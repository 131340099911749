export default [
	{
		title: 'Contratos',
		route: 'home',
		icon: 'FileTextIcon',
	},
	{
		title: 'Financiamento',
		route: 'financiamento',
		icon: 'DollarSignIcon',
	},
	{
		title: 'Solicitações',
		icon: 'PlayIcon',
		children: [
			{
				title: 'Minhas Solicitações',
				route: 'minhas-solicitacoes',
			},
			{
				title: 'Demarcação de Lote',
				route: 'demarcao-de-lote',
			},
			{
				title: 'Programa Roçada',
				route: 'programa-rocada',
			},
		],
	},
	{
		title: 'Documentos',
		route: 'documentos',
		icon: 'ArchiveIcon',
	},
	{
		title: 'Dúvidas',
		route: 'duvidas',
		icon: 'InfoIcon',
	},
	{
		title: 'Fale Conosco',
		route: 'fale-conosco',
		icon: 'MessageSquareIcon',
	},
]
